<template>
  <b-row>
    <b-col class="col-12">
      <h2 class="i-margin-bottom-submodule">
        {{ $t(nameModule) }}
      </h2>
      <hr>
    </b-col>
    <b-col
      class="col-12 pt-1 justify-content-end d-md-inline-flex d-none"
      :class="{'i-only-pi-z' : currentTab == routePi}"
    >
      <ul
        class="navs float-right"
        :class="{'i-only-pi-style' : currentTab == routePi}"
      >
        <li
          v-for="sectionC in sectionsInTab"
          :key="sectionC.id"
          class="i-li-active"
          :class="{'i-tab-option-active' : $route.name == sectionC.route || sectionC.name == routePi && ($route.name == routeA || $route.name == routeQ || $route.name == routeR)}"
          @click="assignRouteY(sectionC)"
        >
          <span class="text-dark">
            {{ $t(sectionC.name) }}
          </span>
          <span
            v-if="sectionC.name == routePi"
            style="margin-left: 5px"
          >
            <feather-icon
              icon="ChevronDownIcon"
              size="16"
              class="rotates"
            />
          </span>
          <span v-else />
          <ul v-if="sectionC.name === routePi && currentTab == routePi">
            <li>
              <b-link @click="$router.push({ name: routeA, params: { id: $route.params.id } })">
                {{ $t('clubs.intellectualProperty.formAgreements.labels.agreements') }}
              </b-link>
            </li>
            <li>
              <b-link @click="$router.push({ name: routeQ, params: { id: $route.params.id } })">
                {{ $t('clubs.intellectualProperty.trademarkRegistration') }}
              </b-link>
            </li>
            <li>
              <b-link @click="$router.push({ name: routeR, params: { id: $route.params.id } })">
                {{ $t('clubs.intellectualProperty.literaryOrArtisticWorkRegistration') }}
              </b-link>
            </li>
          </ul>
        </li>
      </ul>
    </b-col>
    <b-col class="col-md-12 justify-content-end">
      <div class="i-show-drop float-right">
        <div class="i-dropdown">
          <button class="i-drop-section-button">
            {{ $t(optionActiveD) }}
            <span style="margin-left: 5px">
              <feather-icon
                icon="ChevronDownIcon"
                size="16"
                class="rotates"
              />
            </span>
          </button>
          <div class="i-dropdown-content">
            <div
              v-for="sectionD in sectionsInTab"
              :key="sectionD.id"
              :class="$route.name == sectionD.route ? 'i-nav-section-active' : ''"
              @click="assignRouteY(sectionD)"
            >
              {{ $t(sectionD.name) }}
            </div>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { BRow, BCol, BLink } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
  },

  props: {
    nameModule: { String },
    sectionsInTab: { Array },
  },

  data() {
    return {
      optionActiveD: '',
      routePi: 'routes.documentalCollectionRoute.intellectualProperty',
      routeA: 'pi-agreements',
      routeQ: 'pi-trademarks',
      routeR: 'pi-literary-or-artistic-works',
      currentTab: null,
    }
  },

  mounted() {
    if (this.$route.meta.redirectIfProfileIsIncomplete === undefined) {
      if (this.incompleteByImage) {
        this.optionActiveD = this.sectionsInTab[0].name
        this.$router.push({ name: this.sectionsInTab[0].route })
      } else if (this.incomplete) {
        this.optionActiveD = this.sectionsInTab[1].name
        this.$router.push({ name: this.sectionsInTab[1].route })
      } else if (!this.updatedPassword) {
        this.optionActiveD = this.sectionsInTab[2].name
        this.$router.push({ name: this.sectionsInTab[2].route })
      } else {
        this.optionActiveD = this.sectionsInTab[0].name
        this.$router.push({ name: this.sectionsInTab[0].route })
      }
    } else {
      this.optionActiveD = this.sectionsInTab[0].name
      this.$router.push({ name: this.sectionsInTab[0].route })
    }
  },

  computed: {
    ...mapGetters({
      incomplete: 'incomplete',
      incompleteByImage: 'incompleteByImage',
      updatedPassword: 'updatedPassword',
    }),
  },

  methods: {
    assignRouteY(pRoute) {
      this.currentTab = pRoute.name

      pRoute.name != this.routePi ? this.$emit('assign-translate-in-x', false) : this.$emit('assign-translate-in-x', true)

      if (this.$route.name != pRoute.route && pRoute.name != this.routePi) {
        this.optionActiveD = pRoute.name

        this.$route.params.id !== undefined
          ? this.$router.push({ name: pRoute.route, params: { id: this.$route.params.id } })
          : this.$router.push({ name: pRoute.route })
      }
    },
  },
}
</script>

<style scoped>
.i-only-pi-style {
  height: 150px;
  position: absolute;
}
</style>
